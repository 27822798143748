.SuperHubCustomPage {
  $block: &;

  &-content {
    margin: 0 auto;
    max-width: var(--container, 1140px);
  }

  &_fullscreen {
    #{$block}-content {
      max-width: 100%;
    }
  }
}
